import { NgClass, AsyncPipe } from '@angular/common';
import { Component, OnInit }  from '@angular/core';

import { LandingComponent } from "@app/frame-bracket/landing/landing.component";
import { MenuComponent } from "@app/frame-bracket/menu/menu.component";
import { IsDesktopService } from "@common-services/is-desktop.services";
import { TenantService } from "@common-services/tenant.services";
import { CmsContentService } from "@services/cms-content.service";
import { LoaderControllerService } from "@services/loader-controller.service";
import { MaintenanceControlService } from "@services/maintenance-control.service";


@Component({
    selector: 'hun-lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.scss'],
    standalone: true,
  imports: [NgClass, AsyncPipe, MenuComponent, LandingComponent]
})
export class LockComponent implements OnInit {

  constructor(
    public cmsContentService: CmsContentService,
    public isDesktopService: IsDesktopService,
    public tenantService: TenantService,
    public maintenanceControlService: MaintenanceControlService,
    private loaderControllerService: LoaderControllerService
  ) {}

  ngOnInit(): void {
    this.loaderControllerService.isLoading$.next(false);
  }

  isCocpitDataReceived(config) {
    if (!config || !Object.keys(config).length) return false;
    return !(!config.colour_content || !config.text_content || !config.image_content);
  }

}
