@if (maintenanceControlService.isMaintenance$ | async) {
  @if (isCocpitDataReceived(cmsContentService.cmsContent$ | async)) {
    @if ((cmsContentService.cmsContent$ | async); as cmsContentConfig) {
      @if (tenantService.isBracket$ | async) {
        <hun-menu-bracket [hideInfoButton]="true">
          <hun-landing-bracket></hun-landing-bracket>
        </hun-menu-bracket>
      } @else {
        <div
          class="content"
          [ngClass]="{'mobile': !(isDesktopService.isDesktop$ | async)}"
        >
          @if (tenantService.isStreak$ | async) {
            <img [src]="cmsContentConfig['image_content']['iframe-v3-streak-logo']">
          } @else {
            @if (isDesktopService.isDesktop$ | async) {
              <img class="desctop" [src]="cmsContentConfig['image_content']['iframe-v3-supergame-logo-desktop']">
            } @else {
              <img [src]="cmsContentConfig['image_content']['iframe-v3-supergame-logo-mobile']">
            }
          }
          <div class="under-construction-text">
            {{cmsContentConfig['text_content']['iframe-v3-under-construction']}}
          </div>
        </div>
      }
    }
  } @else {
    <div
      class="content"
      [ngClass]="{'mobile': !(isDesktopService.isDesktop$ | async)}">
      <img class="desctop" src="../../../../assets/image/frame-v3/Dark_None.svg">
    </div>
  }
}

